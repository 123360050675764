import React, { useState, useEffect, useCallback } from "react"
import { Link } from "gatsby"
import Cookies from "js-cookie"

import { Button } from "../../common"

import "./cookie-consent.css"

export default function CookieConsent() {
  const [
    hasAcceptedOrRejectedCookies,
    setHasAcceptedOrRejectedCookies,
  ] = useState(Cookies.get("gatsby-gdpr-google-analytics") !== undefined)
  const [mountedClassName, setMountedClassName] = useState(
    "CookieConsent-preMount"
  )

  const onAcceptCookies = useCallback(() => {
    Cookies.set("gatsby-gdpr-google-analytics", true)
    if (window.trackGoogleAnalytics) {
      window.trackGoogleAnalytics()
    }
    setHasAcceptedOrRejectedCookies(true)
  }, [setHasAcceptedOrRejectedCookies])

  const onRejectCookies = useCallback(() => {
    Cookies.set("gatsby-gdpr-google-analytics", false)
    setHasAcceptedOrRejectedCookies(true)
  }, [setHasAcceptedOrRejectedCookies])

  useEffect(() => {
    setMountedClassName("CookieConsent-mounted")
  }, [setMountedClassName])

  if (hasAcceptedOrRejectedCookies) {
    return null
  }

  return (
    <div className={`CookieConsent ${mountedClassName}`}>
      <div className="container mx-auto py-4 px-4 md:py-8 md:px-4">
        <div className="flex justify-between items-center">
          <div className="text-white mr-2 ml-2">
            <h3 className="text-xl">Cookies</h3>
            <p>
              This site uses cookies to offer you a better browsing experience.{" "}
            </p>
            <Link to="/legal-stuff/cookies-policy" className="hover:underline">
              View cookies policy
            </Link>
          </div>
          <div className="md:whitespace-no-wrap">
            <Button className="m-2" onClick={onAcceptCookies}>
              Accept Cookies
            </Button>
            <Button inverted className="m-2" onClick={onRejectCookies}>
              Refuse Cookies
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
